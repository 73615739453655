import { inject, Injectable, ViewContainerRef } from '@angular/core';
import { Practice } from '../models/practice';
import { PracticePdfLayoutComponent } from '../../pages/print-layout/practice-pdf-layout/practice-pdf-layout.component';
import { UserService } from './user.service';
import { PlaceholderSectionElementMap } from '../models/editor-with-section/placeholder-section';
import htmlToPdfmake from 'html-to-pdfmake';
import { Content } from 'pdfmake/interfaces';
import { TranslateService } from '@ngx-translate/core';
import { HttpClient } from '@angular/common/http';
import { filter, lastValueFrom, map, switchMap } from 'rxjs';
import { ConfigurationService } from './configuration.service';
import { toSignal } from '@angular/core/rxjs-interop';
import { AuthService } from 'addiction-auth';
import { environment } from '../../../../src/environments/environment';
import { PdfMake } from '../models/pdf-make';

export type PrintableEntity = Practice;


/**
 * Servizio in root utilizzato per gestire il print dei documenti
 * Importante: Il servizio si basa sul settaggio iniziale di una ViewContainerRef
 * Che permette di generare un componente dinamicamente
 */
@Injectable({
  providedIn: 'root',
})
export class PrintService {
  private viewContainerRef!: ViewContainerRef;
  private userService = inject(UserService);
  private translateService = inject(TranslateService);
  private httpClient = inject(HttpClient);
  private configService = inject(ConfigurationService);
  private authService = inject(AuthService);
  private isUserLogged$ = this.authService.isAuthenticated$.pipe(filter((x) => !!x));
  practiceFooterText = toSignal(
    this.isUserLogged$.pipe(
      switchMap(() => {
        return this.configService.getConfigsByKey(environment.dataleanAppConfigKeys.practiceFooterText);
      }),
      map((configs) => {
        const config = configs.length > 0 ? configs[0] : undefined;
        return typeof config?.value === 'string' ? config.value : undefined;
      })
    )
  );

  private MARGIN_A4_PT = 50;
  private THRESHOLD_HEIGHT = 355 //unità di misura: pt
  pdfMake: PdfMake | undefined;


  async loadPdfMaker() {
    if (!this.pdfMake) {
      /**
       * a causa dell'eccessivo peso della libreria
       * si è deciso di caricarla in maniera lazy
       * in modo da abbassare il peso del bundle initial
       * */

      const pdfMakeModule = await import('pdfmake/build/pdfmake.min');
      const pdfFontsModule = await import('pdfmake/build/vfs_fonts');
      this.pdfMake = pdfMakeModule.default;
      this.pdfMake.vfs = pdfFontsModule.default.pdfMake.vfs;
    }
  }


  setViewContainer(ref: ViewContainerRef) {
    this.viewContainerRef = ref;
  }

  async printPractice(practice: Practice, placeholderMap?: PlaceholderSectionElementMap[]) {
    if (this.viewContainerRef) {
      await this.loadPdfMaker();
      this.viewContainerRef.clear();
      const component = this.viewContainerRef.createComponent(PracticePdfLayoutComponent, {});
      component.instance.practice = practice;
      component.instance.placeholdersMap = placeholderMap;
      const userPracticeFrames = this.userService.userData()?.practiceFrames;

      let headerAsset = { base64: '', width: 300, height: 45 };
      let footerAsset = { base64: '', width: 300, height: 45 };

      // provo a recuperare header e footer del medico
      // se non ce la faccio metto un immagine di default
      try {
        headerAsset = await this.getImageBase64AndDimensions(userPracticeFrames?.header?.url ?? '');
      } catch (error) {
        console.log('error retrieve header img 017017');
        //metto header di default './assets/images/empty-img.png'
        const base = await lastValueFrom(this.getDefaultHeaderFooterImage());
        headerAsset = { base64: base as string, width: 300, height: 45 };
      }
      try {
        footerAsset = await this.getImageBase64AndDimensions(userPracticeFrames?.footer?.url ?? '');
      } catch (error) {
        console.log('error retrieve footer img 017017');
        //metto header di default './assets/images/empty-img.png'
        const base = await lastValueFrom(this.getDefaultHeaderFooterImage());
        footerAsset = { base64: base as string, width: 300, height: 45 };
      }

      const headerDimensions = this.calculateWidthAndHieght(headerAsset.width, headerAsset.height);

      const footerText: string | undefined = this.practiceFooterText();
      let footerTextContent: Content | undefined;
      let footerTextDimensions = { width: 0, height: 0 };

      if (footerText && practice.enableFooterText) {
        const {content, dimensions} = this.createFooterTextContent(footerText);

        footerTextContent = content;
        footerTextDimensions = dimensions;
      }

      const footerDimensions = this.calculateWidthAndHieght(footerAsset.width, footerAsset.height, footerTextDimensions.height);

      // Ottieni l'HTML da trasformare in pdfMake
      const html = component.location.nativeElement.innerHTML;
      // Converti l'HTML in formato compatibile con pdfMake
      const pdfContent: Content[] = htmlToPdfmake(html) as Content[];
      this.translateService.instant('PRACTICE.PRINT.PATIENT_SPACE');

      //aggiungo in testa lo spazio del paziente
      //lascio il commento per avere (per un futuro) un esempio di aggiunta dinamica di un riquadro
      // const patient = practice.patientInfo;
      // pdfContent.unshift({
      //   table: {
      //     widths: ['*'], //tabella con una sola colonna e si espande per riempire lo spazio
      //     body: [
      //       [
      //         {
      //           stack: [
      //             { text: patient.fullName, style: ['html-div', 'ng-star-inserted'] },
      //             {
      //               text: `${this.translateService.instant('PRACTICE.PRINT.BIRTH_PLACE', {
      //                 city: patient.birthInfo.city,
      //                 province: patient.birthInfo.province,
      //               })} ${this.dateService.transform(patient.birthInfo.date, 'dd/MM/yyyy')}`,
      //             },
      //             {
      //               text: `${this.translateService.instant('PRACTICE.PRINT.RESIDENCE_PLACE', {
      //                 city: patient.residencyInfo.city,
      //                 address: patient.residencyInfo.address,
      //               })}`,
      //             },
      //             {
      //               columns: [
      //                 { text: `${this.translateService.instant('PRACTICE.PRINT.ACCEPT_DATE')}` },
      //                 { text: `${this.translateService.instant('PRACTICE.PRINT.SIGNATURE')}` },
      //               ],
      //               style: ['html-div', 'ng-star-inserted', 'signature'],
      //             },
      //           ],
      //         },
      //       ],
      //     ],
      //     dontBreakRows: true,
      //   },
      // });
      // pdfContent.unshift({
      //   text: this.translateService.instant('PRACTICE.PRINT.PATIENT_SPACE'),
      //   style: ['html-div', 'titleBox'],
      // });

      pdfContent.push({
        table: {
          widths: ['*'], //tabella con una sola colonna e si espande per riempire lo spazio
          body: [
            [
              {
                stack: [
                  {
                    text: `${this.translateService.instant('PRACTICE.PRINT.MEDIC_TEXT')}`,
                    style: ['line2'],
                  },
                  {
                    text: `${this.translateService.instant('PRACTICE.PRINT.STAMP_AND_SIGNATURE')}`,
                    style: ['medicSignature'],
                  },
                ],
              },
            ],
          ],
          dontBreakRows: true,
        },
      });

      this.pdfMake?.createPdf({
          pageSize: 'A4', //page width 595.28 x 842
          header: {
            image: headerAsset.base64,
            width: headerDimensions.width,
            height: headerDimensions.height,
            alignment: 'center',
            margin: [0, 0],
          },
          footer: [
            ...(footerTextContent ? [footerTextContent] : []),
            {
              image: footerAsset.base64,
              width: footerDimensions.width,
              height: footerDimensions.height,
              alignment: 'center',
              margin: [0, 0],
            },
          ],
          content: pdfContent,
          styles: {
            medicSignature: {
              alignment: 'left',
              lineHeight: 5,
            },
            line2: {
              lineHeight: 2,
            },
            signature: {
              alignment: 'center',
              lineHeight: 5,
              marginTop: 5,
            },
            titleBox: {
              lineHeight: 2,
              marginTop: 10,
            },
          },
          pageMargins: [
            this.MARGIN_A4_PT,
            headerDimensions.height + 5,
            this.MARGIN_A4_PT,
            footerDimensions.height + footerTextDimensions.height + 5,
          ],
        })
        .getBlob((blob: Blob) => {
          //creo un iframe per poter gestire in questa stessa pagina l'apertura della print()
          //(altrimenti mi si apre una nuova tab)
          const hiddFrame = document.createElement('iframe');
          hiddFrame.style.display = 'none'; // Nascondi l'iframe
          document.body.appendChild(hiddFrame);
          const url = URL.createObjectURL(blob);
          hiddFrame.src = url;

          // const isSafari = /^((?!chrome|android).)*safari/i.test(window.navigator.userAgent);

          // Attendi che l'iframe carichi il PDF, quindi avvia la stampa
          hiddFrame.onload = function () {
            if (hiddFrame.contentWindow) {
              hiddFrame.contentWindow.print();
            }
          };
        });
    } else {
      throw Error('Set a View Container Ref for the service');
    }
  }

  /**
   * calcola le dimensioni delle immagini per FOOTER E HEADER in un foglio A4
   * @param currentWidth ampiezza del footer/header attuale
   * @param currentHeight altezza del footer/header attuale
   * @returns {width:number , height: number} un oggetto che contiene le dimensioni (eventualmente con resize applicato)
   */
  private calculateWidthAndHieght(currentWidth: number, currentHeight: number, heightAlreadyOccupied?: number) {
    // foglio A4 => 842 h 595 w
    const pageWidth = 575; //575 = 595.28 (a4) - 10 margine sx - 10 margine dx
    //limito i componenti ad al massimo 355 (ovvero quasi la metà della pagina)
    const thresholdHeight = this.THRESHOLD_HEIGHT - (heightAlreadyOccupied ?? 0);
    let elementWidth = currentWidth;
    let elementHeight = currentHeight;

    if (currentWidth < pageWidth) {
      //l'elemento sta in pagina per larghezza
      if (currentHeight > thresholdHeight) {
        //se entro significa che l'altezza va oltre la metà pagina quindi faccio una resize
        elementHeight = thresholdHeight; //resize height
        elementWidth = (thresholdHeight * currentWidth) / currentHeight; //newW = (newH * oldW) / oldH
      }
    } else {
      //se la dimensione è più grande della pagina allora mi calcolo la nuova altezza dopo il resize
      elementWidth = pageWidth; //resize width
      elementHeight = (currentHeight / currentWidth) * pageWidth; //(originalHeight / originalWidth) * pdfwidth
      if (elementHeight > thresholdHeight) {
        //se entro significa che l'altezza va oltre la metà pagina quindi faccio una resize
        elementWidth = (thresholdHeight * pageWidth) / elementHeight; //newW = (newH * oldW) / oldH
        elementHeight = thresholdHeight;
      }
    }

    return { width: elementWidth, height: elementHeight };
  }

  private async getImageBase64AndDimensions(url: string): Promise<{ base64: string; width: number; height: number }> {
    try {
      // 1. Fetch l'immagine
      const response = await fetch(url);

      // 2. Controllare se la risposta è valida
      if (!response.ok) {
        throw new Error(`Errore nel recupero dell'immagine: ${response.statusText}`);
      }

      // 3. Convertire la risposta in un blob
      const blob = await response.blob();

      // 4. Usare FileReader per leggere il blob e convertirlo in Base64
      const reader = new FileReader();
      const base64Promise = new Promise<string>((resolve, reject) => {
        reader.onloadend = () => resolve(reader.result as string);
        reader.onerror = (error) => reject(new Error(`Errore nella lettura del file: ${error}`));
      });

      reader.readAsDataURL(blob); // Inizia la lettura del blob come URL di dati

      // 5. Creare un oggetto Image per ottenere le dimensioni
      const img = new Image();
      img.src = await base64Promise;

      // 6. Attendere che l'immagine sia caricata
      const dimensionsPromise = new Promise<{ width: number; height: number }>((resolve, reject) => {
        img.onload = () => {
          resolve({ width: img.width, height: img.height });
        };
        img.onerror = (err) => {
          reject(err);
        };
      });

      // 7. Aspettare che entrambe le promesse siano risolte
      const dimensions = await dimensionsPromise;
      const base64 = await base64Promise;

      return { base64, width: dimensions.width, height: dimensions.height };
    } catch (error) {
      console.error(`Errore: ${JSON.stringify(error)}`);
      throw error;
    }
  }

  //funzione che recupera un file delprogetto
  getDefaultHeaderFooterImage() {
    return this.httpClient.get('assets/images/empty-img.png', { responseType: 'blob' }).pipe(
      map((blob) => {
        return new Promise((resolve, reject) => {
          const reader = new FileReader();
          reader.onloadend = () => {
            resolve(reader.result as string);
          };
          reader.onerror = () => {
            reject('Failed to convert Blob to Base64');
          };
          reader.readAsDataURL(blob);
        });
      })
    );
  }

  /**
   *
   * @param footerText La stringa contente l'HTML da inserire nel footer
   * @returns  {width:number , height: number} un oggetto che contiene le dimensioni (eventualmente con resize applicato)
   */
  private getFooterTextDimensions(footerText: string) {
    //creo un div fittizio a cui aggiunto l'html per avere poi
    //le dimensioni del contenuto
    const div = document.createElement('div');
    div.style.position = 'absolute';
    div.style.visibility = 'hidden';
    // do al div la larghezza e il margine che saranno presenti nel foglio a4
    // in modo da avere l'altezza effettiva del testo
    div.style.width = `${595 - (this.MARGIN_A4_PT * 2)}pt`; //A4 - (MARGIN_A4_PT * 2)
    div.style.maxHeight = `${this.THRESHOLD_HEIGHT}pt`
    div.innerHTML = footerText;

    // Aggiungi il contenitore al body
    document.body.appendChild(div);
    const targetElement = div.firstElementChild as HTMLElement;

    if (!targetElement) {
      document.body.removeChild(div);
      throw new Error('HTML string does not contain a valid element.');
    }

    // Ottieni le dimensioni
    const dimensions = {
      width: targetElement.offsetWidth,
      height: targetElement.offsetHeight,
    };

    // Rimuovi il contenitore temporaneo
    document.body.removeChild(div);
    return this.calculateWidthAndHieght(dimensions.width, dimensions.height);
  }

  private createFooterTextContent(footerText: string): {content: Content, dimensions: {width: number, height: number}} {
    const footerTextDimensions = this.getFooterTextDimensions(footerText);
    const htmlToPdfmakeContent = htmlToPdfmake(footerText);
    const footerTextContent: Content = {
      stack: Array.isArray(htmlToPdfmakeContent) ? htmlToPdfmakeContent : [htmlToPdfmakeContent],
      // se si modifica il valore di margin qui
      // assicurarsi di cambiarlo anche nel metodo getFooterTextDimensions
      margin: [this.MARGIN_A4_PT, 0],
      alignment: 'left',
    };

    return {content: footerTextContent, dimensions: footerTextDimensions};
  }
}
